'use client';

import { Heading1 } from '@/shared/components/Heading1/Heading1';
import { Heading3 } from '@/shared/components/Heading3/Heading3';
import Image from 'next/image';
import BackgroundNotFound from '@/shared/assets/images/notfound-bg.png';
import ShapeNotFound from '@/shared/assets/images/notfound-shape.png';
import { useRouter } from 'next/navigation';
import { Button } from '@/shared/shadcn/ui/button';

export default function NotFoundPage() {
  const router = useRouter();
  return (
    <section className='flex flex-col items-center justify-center relative'>
      <Heading1 className='text-[64px] font-extrabold'>404</Heading1>
      <Heading3 className='text-[32px] font-medium'>
        We’re working on it!
      </Heading3>
      <div className='relative'>
        <Image
          src={ShapeNotFound}
          width={600}
          height={600}
          alt='404'
          priority
          quality={100}
        />
        <div className='absolute top-10 right-28'>
          <Image
            src={BackgroundNotFound}
            width={400}
            height={400}
            alt='404'
            priority
            quality={100}
          />
        </div>
      </div>
      <Button
        onClick={() => router.back()}
        variant={'default'}
        style={{ borderRadius: '16px' }}
        className='bg-[#FFFAE6] border-[#4258ED] border-[1.5px] text-[#4258ED] px-9 -mt-10 z-10'
      >
        Go Home
      </Button>
    </section>
  );
}
