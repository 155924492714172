import { cn } from "@/shared/shadcn/lib/utils";

type Heading3 = {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
};

export function Heading3({ children, className, onClick }: Heading3) {
    return (
        <h3 className={cn('text-2xl', className)} onClick={onClick}>
            {children}
        </h3>
    );
}
