import { cn } from '@/shared/shadcn/lib/utils';

type Heading1 = {
  children: React.ReactNode;
  className?: string;
};

export function Heading1({ children, className }: Heading1) {
  return <h1 className={cn('text-[40px]', className)}>{children}</h1>;
}
